import { useRef, useState } from 'react';
import LazyImage from 'src/components/Common/LazyImage';
import RichText from 'src/components/Common/RichText_Old';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import Transition from 'src/lib/Transition';
import { MoreInfoWidgetTypes } from './types';

const MoreInfoWidget = ({
  headline,
  image,
  text,
  showLessText = 'Show Less',
  showAllText = 'Show All',
  ...rest
}: MoreInfoWidgetTypes) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hiddenTextRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (!isExpanded) {
      track.component({
        category: 'more_info',
        action: 'Show All',
        label: headline.value?.substring(0, 30),
        event: 'event-click',
        guid: rest.id,
      });
    }
    setIsExpanded(!isExpanded);
  };

  const focusOnHiddenText = (e: { propertyName: string }) => {
    e.propertyName === 'visibility' && hiddenTextRef.current?.focus();
  };

  return (
    <div className="max-w-md mx-auto text-center px-16 lg:px-0 py-48">
      {image?.value && <LazyImage {...image} alt="" className="icon-30 mx-auto mb-12" />}
      <RichText tag="p" field={headline} />
      <Transition.Height
        onTransitionEnd={focusOnHiddenText}
        active={isExpanded}
        className={`js-more-info-body ${isExpanded ? 'mt-24' : ''}`}
      >
        <RichText
          tabIndex={-1}
          ref={hiddenTextRef}
          tag="div"
          field={text}
          aria-hidden={!isExpanded}
        />
      </Transition.Height>

      <button
        className="relative text-teal-darker flex items-center mx-auto mt-24"
        aria-expanded={isExpanded}
        onClick={handleClick}
      >
        <span className="border-b mr-4">{isExpanded ? showLessText : showAllText}</span>
        <SvgLoader name="Triangle" size={12} rotate={isExpanded ? 0 : 180} />
      </button>
    </div>
  );
};

export default MoreInfoWidget;
