import Composition from 'src/lib/HOC/Composition';
import MoreInfoWidget from './index';

const { compositionFunction, component } = Composition(MoreInfoWidget)(({ fields, rendering }) => {
  return {
    headline: fields?.Subhead,
    image: fields?.Icon,
    text: fields?.Body,
    id: rendering?.dataSource,
    showAllText: fields?.['Show All']?.value || 'Show All',
    showLessText: fields?.['Show Less']?.value || 'Show Less',
  };
});

export { compositionFunction };
export default component;
